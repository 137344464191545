<template>
  <Content>
    <ShareLinkModal :showing-modal="dialog"
                    :link="link"
                    @show-modal="closeModal"
                    @copied-link="copiedLink"
    />
    <ShareInfoComponent
      @share-link-modal="showShareModal"
    />
    <ActionButton
      :text="'INVITE SUPPORTERS'"
      @click-button="showShareModal"
    />
  </Content>
</template>

<script>
import ShareInfoComponent from '@innerworks_ds/innerworks-storybook-main/src/stories/ShareResult/ShareInfoComponent.vue';
import ShareLinkModal from '@innerworks_ds/innerworks-storybook-main/src/stories/WindowModals/ShareLinkModal/ShareLinkModal.vue';
import ActionButton from '@innerworks_ds/innerworks-storybook-main/src/stories/Button/ActionButton.vue';
import { mapGetters } from 'vuex';
import Vue from 'vue';

export default {
  name: 'SharingInfo',
  components: { ShareInfoComponent, ShareLinkModal, ActionButton },
  data: () => ({
    dialog: false,
    blurTitle: false,
    blurSubtitle: false,
    blurText1: false,
    blurText2: false,
    link: '',
  }),
  computed: {
    ...mapGetters({
      getProfile: 'auth/getProfile',
    }),
  },
  async mounted() {
    if (this.$route.query.opensharelink) {
      this.dialog = true;
    }
    await this.$store.dispatch('showComponent/showHeaderMain');
    this.$api.personalityTypeReport.getUserShareLink(this.getProfile.id).then((res) => {
      this.link = `${window.location.protocol}//${window.location.host}${res.invitationLink}`;
    });
  },
  methods: {
    copiedLink() {
      Vue.notify({
        type: 'success',
        title: 'Link copied',
      });
    },
    showShareModal() {
      this.dialog = true;
    },
    closeModal(value, redirectLink) {
      this.dialog = value;
      if (redirectLink) {
        this.$router.push({ name: redirectLink });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
  position: relative;
  font-family: $newDefaultFont;
}

</style>
